import React from "react";
import moment from "moment";
import Card from "../components/generic/Card";
import Table from "../components/generic/Table";
import Stat from "../components/generic/Stat";
import UpsertSupplier from "../modals/UpsertSupplier";

export default class extends React.Component {
    constructor() {
        super();
        this.state = {
            suppliers: null,
            loading: true,
            createSupplierModal: false,
            supplierInFocus: null
        }
    }
    componentDidMount() {
        this.getSuppliers();
    }
    async getSuppliers() {
        this.setState({
            loading: true
        }, async () => {
            const res = await this.props.api.get("/suppliers");
            this.setState({
                suppliers: res.data.data,
                loading: false,
                query: ""
            });
        });
    }
    render() {
        return (
            <div className="route-container">
                <div className="col-start-2">
                    {this.state.loading ? null : <div className="padding-20 col-1 gap-20" style={{ gridTemplateRows: "min-content auto" }}>
                        <div className="col-2 gap-20">
                            <Stat title="Total Active Suppliers" icon="arrow-circle-right" iconColour="blue" stat={this.state.suppliers.filter(x => x.active).length} />
                            <Stat title="Total Available Suppliers" icon="check" iconColour="green" stat={this.state.suppliers.filter(x => x.available).length} />
                        </div>

                        {this.state.supplierModal && <UpsertSupplier supplier={this.state.supplierInFocus} api={this.props.api} onExit={saved => {
                            if (saved) this.getSuppliers();
                            this.setState({
                                supplierModal: false,
                                supplierInFocus: null
                            })
                        }} />}

                        <Card className="padding-16 col-1 gap-16" style={{ gridTemplateRows: "min-content auto" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "auto min-content" }}>
                                <input style={{ maxWidth: 300 }} type="text" className="ui-input ui-compact" placeholder="Search..." onChange={e => this.setState({ query: e.currentTarget.value.toLowerCase() })} />
                                <button onClick={() => this.setState({ supplierModal: true })} style={{ alignSelf: "flex-end" }} className="ui-btn-secondary ui-btn-compact padding-left-16 padding-right-16"><i className="fa fa-plus" /></button>
                            </div>
                            <Table compact onClick={i => this.setState({
                                supplierModal: true,
                                supplierInFocus: this.state.suppliers.find(x => x.id === this.state.suppliers.filter(x => {
                                    return x.name.toLowerCase().indexOf(this.state.query) > -1
                                })[i].id)
                            })} data={this.state.suppliers.filter(x => {
                                return x.name.toLowerCase().indexOf(this.state.query) > -1
                            }).map(x => ({
                                "Name": x.name,
                                "Available": !!x.available,
                                "Active": x.active,
                                "Last Updated": moment(x.updatedAt).format("DD/MM/YYYY HH:mm")
                            }))} />
                        </Card>
                    </div>
                    }
                </div>
            </div>
        )
    }
}