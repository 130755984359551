import React from "react";
import moment from "moment";
import Card from "../components/generic/Card";
import Table from "../components/generic/Table";
import Stat from "../components/generic/Stat";
import UpsertUser from "../modals/UpsertUser";
import UpsertPartner from "../modals/UpsertPartner";

export default class extends React.Component {
    constructor() {
        super();
        this.state = {
            users: null,
            partners: null,
            loading: true,
            userQuery: "",
            partnerQuery: ""
        }
    }
    componentDidMount() {
        this.getData();
    }
    async getData() {
        this.setState({
            loading: true
        }, async () => {
            const users = await this.props.api.get("/users");
            const partners = await this.props.api.get("/partners");
            this.setState({
                users: users.data.data,
                partners: partners.data.data,
                loading: false
            });
        });
    }
    render() {
        return (
            <div className="route-container">

                {this.state.userModal && <UpsertUser user={this.state.userInFocus} api={this.props.api} onExit={saved => {
                    if (saved) this.getData();
                    this.setState({
                        userModal: false,
                        userInFocus: null
                    })
                }} />}

                {this.state.partnerModal && <UpsertPartner partner={this.state.partnerInFocus} api={this.props.api} onExit={saved => {
                    if (saved) this.getData();
                    this.setState({
                        partnerModal: false,
                        partnerInFocus: null
                    })
                }} />}

                <div className="col-start-2">
                    {this.state.loading ? null : <div className="padding-20 col-1 gap-20" style={{ gridTemplateRows: "min-content auto" }}>
                        <div className="col-2 gap-20">
                            <Stat title="Active Users" icon="arrow-circle-right" iconColour="blue" stat={this.state.users.filter(x => x.active).length} />
                            <Stat title="Active Partners" icon="check" iconColour="green" stat={this.state.partners.filter(x => x.active).length} />
                            <Card className="padding-16 col-1 gap-16" style={{ gridTemplateRows: "min-content auto", height: "600px" }}>
                                <div style={{ display: "grid", gridTemplateColumns: "auto min-content" }}>
                                    <input style={{ maxWidth: 300 }} type="text" className="ui-input ui-compact" placeholder="Search..." onChange={e => this.setState({ userQuery: e.currentTarget.value.toLowerCase() })} />
                                    <button onClick={() => this.setState({userModal: true})} style={{ alignSelf: "flex-end" }} className="ui-btn-secondary ui-btn-compact padding-left-16 padding-right-16"><i className="fa fa-plus" /></button>
                                </div>
                                <Table onClick={i => {
                                    this.setState({
                                        userModal: true,
                                        userInFocus: this.state.users.find(x => x.id === this.state.users.filter(x => {
                                            return x.name.toLowerCase().indexOf(this.state.userQuery) > -1
                                        })[i].id)
                                    });
                                }} compact data={this.state.users.filter(x => {
                                    return x.name.toLowerCase().indexOf(this.state.userQuery) > -1
                                }).map(x => ({
                                    "Name": x.name,
                                    "Email": x.email,
                                    "Active": x.active,
                                    "Last Updated": moment(x.updatedAt).format("DD/MM/YYYY HH:mm")
                                }))} />
                            </Card>
                            <Card className="padding-16 col-1 gap-16" style={{ gridTemplateRows: "min-content auto" }}>
                                <div style={{ display: "grid", gridTemplateColumns: "auto min-content" }}>
                                    <input style={{ maxWidth: 300 }} type="text" className="ui-input ui-compact" placeholder="Search..." onChange={e => this.setState({ partnerQuery: e.currentTarget.value.toLowerCase() })} />
                                    <button onClick={() => this.setState({partnerModal: true})} style={{ alignSelf: "flex-end" }} className="ui-btn-secondary ui-btn-compact padding-left-16 padding-right-16"><i className="fa fa-plus" /></button>
                                </div>
                                <Table onClick={i => {
                                    this.setState({
                                        partnerModal: true,
                                        partnerInFocus: this.state.partners.find(x => x.id === this.state.partners.filter(x => {
                                            return x.owner.toLowerCase().indexOf(this.state.partnerQuery) > -1
                                        })[i].id)
                                    });
                                }} compact data={this.state.partners.filter(x => {
                                    return x.owner.toLowerCase().indexOf(this.state.partnerQuery) > -1
                                }).map(x => ({
                                    "Owner": x.owner,
                                    "Active": x.active,
                                    "Last Updated": moment(x.updatedAt).format("DD/MM/YYYY HH:mm")
                                }))} />
                            </Card>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )

    }
}