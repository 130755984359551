import React from "react";
import moment from "moment";
import Table from "../components/generic/Table";
import TariffOptions from "../modals/TariffOptions";
import TariffUpload from "../modals/TariffUpload";


export default class extends React.Component {
    constructor() {
        super();
        this.state = {
            suppliers: [],
            supplierId: "",
            view: "live",
            tariffs: [],
            tariffModal: false,
            tariffInFocus: null,
            uploadData: null,
            uploadModal: false
        }
    }
    componentDidMount() {
        this.getSuppliers();
    }
    async getSuppliers() {
        const res = await this.props.api.get("/suppliers");
        this.setState({
            suppliers: res.data.data
        });
    }
    async search() {
        this.setState({
            tariffs: []
        }, async () => {
            const res = await this.props.api.get(`/tariffs?supplierId=${this.state.supplierId}${this.state.view ? `&view=${this.state.view}` : ""}`);
            this.setState({
                tariffs: res.data.data
            });
        });
    }
    csvToArray(text) {
        let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l;
        for (l of text) {
            if ('"' === l) {
                if (s && l === p) row[i] += l;
                s = !s;
            } else if (',' === l && s) l = row[++i] = '';
            else if ('\n' === l && s) {
                if ('\r' === p) row[i] = row[i].slice(0, -1);
                row = ret[++r] = [l = '']; i = 0;
            } else row[i] += l;
            p = l;
        }
        return ret;
    }
    async handleUpload(e) {
        const file = e.target.files[0];
        if (file.name.indexOf(".csv") > -1) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                let csv = this.csvToArray(atob(fileReader.result.split("base64,")[1]));
                const data = [];
                const keys = csv.shift();
                for(let row of csv){
                    const newRow = {};
                    for(let i = 0;i < row.length;i++){
                        newRow[keys[i]] = row[i];
                    }
                    data.push(newRow);
                }
                this.setState({
                    uploadData: data.map(x => ({
                        ...x,
                        startDate: x.startDate ? moment(x.startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                        endDate: x.endDate ? moment(x.endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                        expiryDate: x.expiryDate ? moment(x.expiryDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null
                      })).map(x => {
                        return Object.keys(x).reduce((j,y) => {
                          if(x[y]) j[y] = x[y];
                          return j;
                        }, {})
                      }),
                    uploadModal: true
                })
            }
        }
    }
    render() {
        return (
            <div className="col-1 gap-10 padding-10" style={{ height: "calc(100vh - 60px)", gridTemplateRows: "min-content auto" }}>
                <div style={{ display: "grid", gridTemplateColumns: "auto min-content" }}>
                    <div className="col-3 gap-10" style={{ gridTemplateColumns: "300px 300px min-content" }}>
                        <select className="ui-select ui-select-compact" onChange={e => this.setState({ supplierId: e.currentTarget.value })}>
                            <option value="">All Suppliers</option>
                            {
                                this.state.suppliers.map(x => <option value={x.id}>{x.name}</option>)
                            }
                        </select>
                        <select className="ui-select ui-select-compact" value={this.state.view} onChange={e => this.setState({ view: e.currentTarget.value })}>
                            <option value="live">Live Only</option>
                            <option value="historic">Historic Only</option>
                            <option value="standard">Standard Only</option>
                            <option value="">All Tariffs</option>
                        </select>
                        <button onClick={() => this.search()} className="ui-btn-primary ui-btn-compact padding-left-16 padding-right-16"><i className="fa fa-search" /></button>

                    </div>
                    <div style={{ alignSelf: "flex-end" }}>
                        <label for="upload" style={{ display: "block" }} className="ui-btn-secondary ui-btn-compact padding-left-16 padding-right-16"><i className="fa fa-upload" /></label>
                        <input type="file" id="upload" accept="text/csv" style={{ display: "none" }} onChange={e => this.handleUpload(e)} />
                    </div>
                </div>

                {this.state.tariffModal && <TariffOptions view={this.state.view} tariff={this.state.tariffInFocus} api={this.props.api} onExit={saved => {
                    if (saved) this.search();
                    this.setState({
                        tariffModal: false,
                        tariffInFocus: null
                    })
                }} />}

                {this.state.uploadModal && <TariffUpload data={this.state.uploadData} api={this.props.api} onExit={saved => {
                    if (saved) this.search();
                    this.setState({
                        uploadModal: false,
                        uploadData: null
                    })
                }} />}

                <Table onClick={i => this.setState({
                    tariffModal: true,
                    tariffInFocus: this.state.tariffs[i]
                })} compact data={this.state.tariffs.map(x => ({
                    "Supplier": this.state.suppliers.find(y => y.id === x.supplierId).name,
                    "Name": x.name,
                    "Switchable": x.switchable,
                    "Term": x.term,
                    "End Date": x.endDate ? moment(x.endDate).format("DD/MM/YYYY") : null,
                    "Expiry Date": x.expiryDate ? moment(x.expiryDate).format("DD/MM/YYYY") : null
                }))} />
            </div>
        )
    }
}