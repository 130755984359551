import React from "react";

import Axios from "../Axios";
import Particles from 'react-particles-js';
import Card from "../components/generic/Card";

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            email: "",
            password: "",
            invalid: false
        };
    }
    componentDidMount() {
        if (localStorage.sges_key) this.props.history.push("/portal");
    }
    signInAttempt = e => {
        e.preventDefault();
        this.setState(
            {
                loading: true
            },
            async () => {
                try {
                    const res = await Axios().post("auth/signin", {
                        email: this.state.email,
                        password: this.state.password
                    });
                    localStorage.setItem("sges_key", res.data.data.token);
                    this.props.history.push("/portal");
                } catch (e) {
                    this.setState(
                        {
                            loading: false,
                            invalid: true
                        }
                    );
                }
            }
        );
    };
    render() {
        return (
            <div className="sign-in-container col-1">
                <Particles params={{
                    particles: {
                        number: {
                            value: 600
                        },
                        color: {
                            value: ['#D51F57', '#EC9C3A', '#60BFC7', '#2F7CAE', '#76B44D']
                        },
                        shape: {
                            type: 'circle',
                            stroke: {
                                width: 0,
                                color: '#000000'
                            },
                            polygon: {
                                nb_sides: 5
                            }
                        },
                        opacity: {
                            value: 1
                        },
                        size: {
                            value: 2,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 0.025,
                                size_min: 0.1,
                                sync: false
                            }
                        },
                        line_linked: {
                            enable: false
                        },
                        move: {
                            enable: true,
                            speed: 5,
                            direction: 'none',
                            random: false,
                            straight: false,
                            out_mode: 'out',
                            bounce: true,
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200
                            }
                        }
                    },
                    interactivity: {
                        detect_on: 'canvas',
                        events: {
                            onhover: {
                                enable: true,
                                mode: 'repulse'
                            },
                            onclick: {
                                enable: true,
                                mode: 'push'
                            },
                            resize: true
                        },
                        modes: {
                            repulse: {
                                distance: 150,
                                duration: 0.4
                            },
                            push: {
                                particles_nb: 50
                            }
                        }
                    },
                    retina_detect: true
                }} />
                <Card>
                    <form className="col-1 gap-30" onSubmit={this.signInAttempt}>
                        <img src={require("../assets/logo.svg")} alt="SGE" />
                        <div>
                            <h1 className="ui-title-one">Sign in to get started.</h1>
                        </div>
                        <div className="col-1 gap-20">
                            <div className="col-1 gap-10">
                                <label className="ui-label">Email Address</label>
                                <input className={`ui-input ${this.state.invalid ? "warning" : ""}`} type="email" name="email" onChange={e =>
                                    this.setState({ email: e.currentTarget.value })
                                } required />
                            </div>
                            <div className="col-1 gap-10">
                                <label className="ui-label">Password</label>
                                <input className={`ui-input ${this.state.invalid ? "warning" : ""}`} type="password" required
                                    type="password"
                                    onChange={e =>
                                        this.setState({ password: e.currentTarget.value })
                                    } />
                            </div>
                        </div>
                        <div>
                            <button className="ui-btn ui-btn-primary">Sign In</button>
                        </div>
                    </form>
                </Card>
            </div>
        );
    }
}

export default SignIn;