import React from "react";
import Card from "./Card";

export default props => <Card className="ui-stat padding-30 col-1 gap-20">
    <h3 className="ui-title-four">{props.title}</h3>
    <h4 className="ui-title-one">
        {props.icon ? <i className={`fad fa-${props.icon} colour-${props.iconColour} margin-right-16`}/> : null}
        {props.stat}
    </h4>
    {props.content && <p className="ui-title-five ui-title-sub">{props.content}</p>}
</Card>