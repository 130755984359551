import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import DataExport from "../views/reporting/data-files/export";
import ReconExport from "../views/reporting/reconciliation/export";
import ReconUpdate from "../views/reporting/reconciliation/update";
import LiveView from "../views/reporting/audit-report/live";

const Routes = props => (
  <Switch>
      <Route path="/portal/reporting/data-files/export" render={internalProps => <DataExport {...internalProps} {...props} />} />
      <Route path="/portal/reporting/reconciliation/export" render={internalProps => <ReconExport {...internalProps} {...props} />} />
      <Route path="/portal/reporting/reconciliation/update" render={internalProps => <ReconUpdate {...internalProps} {...props} />} />
      <Route path="/portal/reporting/audit-report/live" render={internalProps => <LiveView {...internalProps} {...props} />} />
      <Route exact path="/portal/reporting/audit-report" render={() => (<Redirect to="/portal/reporting/audit-report/live" />)} /> 
      <Route exact path="/portal/reporting/reconciliation" render={() => (<Redirect to="/portal/reporting/reconciliation/export" />)} /> 
      <Route render={() => <Redirect to="/portal/reporting/data-files/export" />} />
  </Switch>
);

export default Routes;
