import React from "react";
import Stat from "../components/generic/Stat";
import {
    Tooltip,
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis
} from "recharts";
import Card from "../components/generic/Card";
import moment from "moment";

const colors = ["#337DAF", "#79B54C"];

export default class extends React.Component {
    constructor() {
        super();
        this.state = {
            todaysQuotes: null,
            todaysSwitches: null,
            totalSwitches: null,
            totalQuotes: null,
            remarketableQuotes: null,
            quotesSnapshot: null,
            switchesSnapshot: null
        }
    }
    async componentDidMount() {
        const res = await this.props.api.get(`/dashboard`);
        console.log(res.data.data)
        this.setState(res.data.data, () => console.log(this.state.quotesSnapshot
            .sort((a, b) => a.created_at - b.created_at)
            .map(x => ({
                name: moment(x.created_at).format("YYYY-MM-DD"),
                Switches: this.state.switchesSnapshot.find(y => y.created_at === x.created_at) ? this.state.switchesSnapshot.find(y => y.created_at === x.created_at).count : 0,
                Quotes: x.count
            }))));
    }
    render() {
        return this.state.todaysQuotes === null ? null : (
            <div className="padding-10">
                <div className="col-3 gap-10">
                    <Stat title="Total Quotes" icon="stream" iconColour="blue" stat={this.state.totalQuotes} content="How many quotes visitors have had." />
                    <Stat title="Total Customers" icon="users" iconColour="green" stat={this.state.totalSwitches} content="How many customers we have." />
                    <Stat title="Remarketing Rate" icon="lightbulb-on" stat={((this.state.remarketableQuotes / this.state.totalQuotes) * 100).toFixed(1) + "%"} content="How many visitors left an email upon quote." />
                    <Stat title="Today's Quotes" icon="stream" iconColour="blue" stat={this.state.todaysQuotes} content="How many visitors have had a quote today." />
                    <Stat title="Today's Switches" icon="check-circle" iconColour="green" stat={this.state.todaysSwitches} content="How many visitors switched provider today." />
                    <Stat title="Today's Conversion Rate" icon="badge-percent" stat={!this.state.todaysQuotes || !this.state.todaysSwitches ? "0%" : ((this.state.todaysSwitches / this.state.todaysQuotes) * 100).toFixed(1) + "%"} content="The conversion rate against getting a quote." />
                </div>
                <div className="margin-top-10">
                    <Card style={{height: 500}} className="padding-25">
                        <ResponsiveContainer>
                            <LineChart
                                data={this.state.quotesSnapshot
                                    .sort((a, b) => a.created_at - b.created_at)
                                    .map(x => ({
                                        name: moment(x.created_at).format("YYYY-MM-DD"),
                                        Switches: this.state.switchesSnapshot.find(y => y.created_at === x.created_at) ? this.state.switchesSnapshot.find(y => y.created_at === x.created_at).count : 0,
                                        Quotes: x.count
                                    }))}
                            >
                                <XAxis height={20} dataKey="name" />
                                <YAxis
                                    width={50}
                                    type="number"
                                    domain={[0, dataMax => dataMax * 3]}
                                />
                                <Tooltip
                                    cursor={{
                                        fill: getComputedStyle(
                                            document.documentElement
                                        ).getPropertyValue("--ui-border")
                                    }}
                                    formatter={value =>
                                        new Intl.NumberFormat("en").format(value)
                                    }
                                />
                                <Line
                                    isAnimationActive={false}
                                    dataKey="Quotes"
                                    stroke={colors[0]}
                                    dot={false}
                                />
                                <Line
                                    isAnimationActive={false}
                                    dataKey="Switches"
                                    stroke={colors[1]}
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Card>
                </div>
            </div>
        )
    }
}