import React from "react";
import Card from "../../../components/generic/Card";
import Table from "../../../components/generic/Table";
import GenericConfirm from "../../../modals/GenericConfirm";
import moment from "moment";
import downloadFile from "../../../components/helpers/DownloadFile";

export default class extends React.Component {
    constructor() {
        super();
        this.state = {
            suppliers: [],
            data: [],
            startDate: moment().startOf("day"),
            endDate: moment().endOf("day"),
            confirmExportModal: false,
            filePasswordModal: false,
            filePassword: null
        }
    }
    async componentDidMount() {
        const res = await this.props.api.get("/suppliers");
        this.setState({
            suppliers: res.data.data
        });
    }
    async search() {
        const res = await this.props.api.get(`/reporting/data-files/export?start=${this.state.startDate.format("YYYY-MM-DDTHH:mm")}&end=${this.state.endDate.format("YYYY-MM-DDTHH:mm")}`);
        this.setState({
            data: res.data.data.map(x => ({
                ...x,
                psr: x.psr ? Object.values(x.psr).join(", ") : null,
                createdAt: moment(x.createdAt).format("DD/MM/YYYY HH:mm"),
                updatedAt: moment(x.updatedAt).format("DD/MM/YYYY HH:mm")
            }))
        });
    }
    render() {
        return <div className="col-1 padding-10">
            {
                this.state.filePasswordModal ? <GenericConfirm cancelText="Close" disableConfirm={true} title="Your report has downloaded" subTitle={`It is password protected.`} body={<>To open this report you will need the password: <span className="colour-green">{this.state.filePassword}</span>.<br/><br/>Be sure to store this as it will not be shown again.</>} onExit={async () => {
                    this.setState({
                        filePasswordModal: false,
                        filePassword: null
                    }, () => this.search())
                }}/> : null
            }
            {
                this.state.confirmExportModal ? <GenericConfirm title="Are you sure?" subTitle={`This will make changes to the customer database.`} body={`This will mark all customers stage in this export to "PASSED_TO_SUPPLIER"`}
                    onExit={async yes => {
                        if (yes) {
                            const res = await this.props.api.get(`/reporting/data-files/export?start=${this.state.startDate.format("YYYY-MM-DDTHH:mm")}&end=${this.state.endDate.format("YYYY-MM-DDTHH:mm")}&file=true`);
                            const linkSource = `data:application/octet-stream;base64,${res.data.data.file}`;
                            const downloadLink = document.createElement("a");
                            downloadLink.href = linkSource;
                            downloadLink.download = `sge_data_file_${moment(this.state.start).format("DDMMYYYYHHmm")}_${moment(this.state.end).format("DDMMYYYYHHmm")}_${moment().format("DDMMYYYYHHmm")}.xlsx`;
                            downloadLink.click();
                            this.setState({
                                filePasswordModal: true,
                                filePassword: res.data.data.password,
                                confirmExportModal: false
                            });
                        } else {
                            this.setState({
                                confirmExportModal: false
                            });
                        }
                    }} /> : null
            }
            <Card className="padding-16 col-1 gap-16" style={{ gridTemplateRows: "min-content auto" }}>
                <div style={{ display: "grid", gridTemplateColumns: "auto min-content" }}>
                    <div className="col-2 gap-10" style={{ gridTemplateColumns: "300px 300px" }}>
                        <div className="col-1 gap-10">
                            <p className="ui-label">Start date</p>
                            <input
                                className="ui-input"
                                defaultValue={this.state.startDate.format("YYYY-MM-DDTHH:mm")}
                                onChange={e =>
                                    this.setState({ startDate: moment(e.currentTarget.value) })
                                }
                                type="datetime-local"
                                placeholder="Start date"
                            />
                        </div>
                        <div className="col-1 gap-10">
                            <p className="ui-label">End date</p>
                            <input
                                className="ui-input"
                                defaultValue={this.state.endDate.format("YYYY-MM-DDTHH:mm")}
                                onChange={e =>
                                    this.setState({ endDate: moment(e.currentTarget.value) })
                                }
                                type="datetime-local"
                                placeholder="End date"
                            />
                        </div>
                    </div>
                    <div style={{ alignSelf: "flex-end" }} className="col-2">
                        <button onClick={() => this.search()} className="ui-btn-primary ui-btn-compact padding-left-16 padding-right-16 margin-right-10"><i className="fa fa-search" /></button>
                        <button onClick={() => this.state.data.length ? this.setState({ confirmExportModal: true }) : () => { }} className="ui-btn-secondary ui-btn-compact padding-left-16 padding-right-16"><i className="fa fa-download" /></button>
                    </div>
                </div>
                <Table compact data={this.state.data} />
            </Card>
        </div>
    }
}