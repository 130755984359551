import React from "react";
import Modal from "../components/generic/Modal";
import Card from "../components/generic/Card";

class UpsertSupplier extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: props.supplier ? props.supplier.id : null,
      name: props.supplier ? props.supplier.name : "",
      telephone: props.supplier ? props.supplier.telephone : "",
      website: props.supplier ? props.supplier.website : "",
      active: props.supplier ? props.supplier.active : true,
      available: props.supplier ? props.supplier.available : false,
      logo: props.supplier ? props.supplier.logo : null
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  saveSupplier = async e => {
    e.preventDefault();
    const supplier = {
      id: this.state.id,
      name: this.state.name,
      telephone: this.state.telephone,
      website: this.state.website,
      active: this.state.active,
      available: this.state.available
    }
    if(this.state.logo){
      if(this.state.logo.indexOf("data:image") > -1){
        supplier.logo = this.state.logo;
      }
    }
    await this.props.api.post("suppliers", supplier);
    this.props.onExit(true);
  };
  async handleLogoChange(e) {
    const file = e.target.files[0];
    if (file.type === "image/png" || file.type.indexOf("image/jp") > -1) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        this.setState({
          logo: fileReader.result
        });
      }
    }
  }
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card dark style={{ minWidth: 400, minHeight: 300 }}>
            <form className="padding-30 gap-16 col-1" onSubmit={this.saveSupplier}>
              <h1 className="ui-title-three margin-bottom-20">
                {
                  this.props.supplier ? `Update "${this.props.supplier.name}"` : "Create a Supplier"
                }
                </h1>
              <div className="col-1 gap-16">
                <div className="col-1 gap-10">
                  <p className="ui-label">Name</p>
                  <input
                    type="text"
                    required
                    className="ui-input"
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Logo (PNG or JPEG Only)</p>
                  <label for="logo">
                      <div className="ui-img-preview" style={{ backgroundColor: "white" }}>
                        <img src={this.state.logo ? this.state.logo : ""} />
                      </div>
                    </label>
                    <input type="file" id="logo" style={{ display: "none" }} onChange={e => this.handleLogoChange(e)} />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Website</p>
                  <input
                    type="text"
                    required
                    className="ui-input"
                    value={this.state.website}
                    onChange={e => this.setState({ website: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Telephone</p>
                  <input
                    type="text"
                    className="ui-input"
                    value={this.state.telephone}
                    onChange={e => this.setState({ telephone: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Available</p>
                  <select className="ui-select" value={`${this.state.available ? "true" : "false"}`} onChange={e => this.setState({ available: e.currentTarget.value === "true" })}>
                    <option value="true">Yes, allow switching</option>
                    <option value="false">No, viewing only</option>
                  </select>
                </div>
                {
                  this.props.supplier && <div className="col-1 gap-10">
                    <p className="ui-label">Active</p>
                    <select className="ui-select" value={`${this.state.active}`} onChange={e => this.setState({ active: e.currentTarget.value === "true" })}>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                }
              </div>
              <div>
                <button
                  type="button"
                  className="ui-btn-compact margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary ui-btn-compact margin-top-20">
                  {this.props.supplier ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default UpsertSupplier;
