import React from "react";
import Modal from "../components/generic/Modal";
import Card from "../components/generic/Card";
import Table from "../components/generic/Table";
import moment from "moment";
import GenericConfirm from "./GenericConfirm";
import downloadFile from "../components/helpers/DownloadFile";
import { json2csv } from "json-2-csv";

class TariffOptions extends React.Component {
  constructor(){
    super();
    this.state = {
      result: null
    }
  }
  async upload(){
    this.setState({
      loading: true
    }, async () => {
      const res = await this.props.api.post(`/tariffs/upload`, {
        nodes: this.props.data
      });
      this.setState({
        loading: false,
        result: res.data.data
      });
    });
  }
  render() {
    return this.state.result ? (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card dark style={{ minWidth: "70vw" }}>
            <div className="padding-30 gap-16 col-1">
              <h1 className="ui-title-three margin-bottom-20">
                Tariff Upload - Results
              </h1>

              <Table compact data={this.state.result.map((x,i) => ({
                "Row Index": i+2,
                "Outcome": x.replace(/tariff./g, "")
              }))} style={{height: "50vh"}}/>

              <div>
                <button
                  type="button"
                  className="ui-btn-compact margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Close
                </button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    ) : ( 
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card dark style={{ minWidth: "70vw" }}>
            <div className="padding-30 gap-16 col-1">
              <h1 className="ui-title-three margin-bottom-20">
                Tariff Upload
              </h1>

              <Table compact data={this.props.data} style={{height: "50vh"}}/>

              <div>
                <button
                  type="button"
                  className="ui-btn-compact margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary ui-btn-compact margin-top-20" onClick={() => this.upload()}>
                  Confirm &amp; Attempt Upload
                </button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default TariffOptions;
