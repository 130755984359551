import React from "react";

class Table extends React.Component {
  formatCell(d){
    if (typeof d === "undefined" || d === null) {
      return "N/A";
    } else if (typeof d === "boolean") {
      return d ? "Yes" : "No";
    } else if (typeof d === "number") {
      return d.toLocaleString();
    } else if (d instanceof Date) {
      return d.toLocaleString();
    } else if (typeof d === "object") {
      return d;
    } else {
      return String(d);
    }
  }
  render() {
    return (
      <div className={`ui-table ${this.props.compact ? "ui-table-compact" : ""}`} style={this.props.style ? this.props.style : null}>
        <table>
          <thead>
            <tr>
              {this.props.data.length ? Object.keys(this.props.data[0]).map(x => {
                if(x === "rowColour"){
                  return null;
                } else if(x === "actions"){
                  return <th>Actions</th>
                } else {
                  return <th>{x}</th>;
                }
            }) : null}
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((d,i) => (
              <tr onClick={() => this.props.onClick ? this.props.onClick(i) : null} style={{backgroundColor: d.rowColour ? `var(--ui-${d.rowColour})` : ""}}>
                {Object.values(d).map((x,i) => {
                  if(Object.keys(d)[i] === "rowColour") {
                    return null
                  } else if(Object.keys(d)[i] === "actions"){
                    return <td className="ui-table-actions">
                      {
                        Object.values(d)[i].map(x => <div onClick={x.onClick}><i className={`fa fa-${x.icon} colour-${x.colour}`}></i></div>)
                      }
                    </td>
                  } else {
                    return <td>{this.formatCell(x)}</td>;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
