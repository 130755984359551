import React from "react";
import StatusPage from "../../../components/generic/StatusPage";

export default class extends React.Component {
    constructor() {
        super();
    }
    async componentDidMount() {
    }
    render() {
        return <StatusPage heading="Section coming soon" subHeading="This section is undergoing development" icon="chart-network"/>
    }
}