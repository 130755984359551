import React from "react";
import { Redirect, NavLink } from "react-router-dom";
import Routes from "../routes/Reporting";

export default class extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        return <div className="reporting-container">
            <div className="sidebar">
                <NavLink to="/portal/reporting/data-files" activeClassName="active" className="ui-label"><i className="fad fa-database margin-right-10"></i>Data Files</NavLink>
                <div className="navigation-options">
                    <NavLink to="/portal/reporting/data-files/export" activeClassName="active" className="ui-label">Export Reports</NavLink>
                </div>
                <NavLink to="/portal/reporting/reconciliation" activeClassName="active" className="ui-label margin-top-10"><i className="fad fa-handshake margin-right-10"></i>Reconciliation</NavLink>
                <div className="navigation-options">
                    <NavLink to="/portal/reporting/reconciliation/export" activeClassName="active" className="ui-label">Export Reports</NavLink>
                    <NavLink to="/portal/reporting/reconciliation/update" activeClassName="active" className="ui-label">Update Records</NavLink>
                </div>
                <NavLink to="/portal/reporting/audit-report" activeClassName="active" className="ui-label margin-top-10"><i className="fad fa-stream margin-right-10"></i>Audit Report</NavLink>
                <div className="navigation-options">
                    <NavLink to="/portal/reporting/audit-report/live" activeClassName="active" className="ui-label">Live View</NavLink>
                </div>
            </div>
            <div className="container">
                <Routes
                    api={this.props.api}
                />
            </div>
        </div>
    }
}