import React from "react";
import Modal from "../components/generic/Modal";
import Card from "../components/generic/Card";
import Table from "../components/generic/Table";
import moment from "moment";
import GenericConfirm from "./GenericConfirm";
import downloadFile from "../components/helpers/DownloadFile";
import { json2csv } from "json-2-csv";

class TariffOptions extends React.Component {
  constructor(){
    super();
    this.state = {
      nodes: [],
      confirmDeactivationModal: false
    }
  }
  async componentDidMount() {
    const res = await this.props.api.get(`/tariffs?supplierId=${this.props.tariff.supplierId}&name=${encodeURIComponent(this.props.tariff.name)}${this.props.view ? `&view=${this.props.view}` : ""}`);
    this.setState({
      nodes: res.data.data.map(x => ({
        ...x,
        id: String(x.id),
        term: x.term ? x.term : "",
        startDate: x.startDate ? moment(x.startDate).format("DD/MM/YYYY") : "",
        endDate: x.endDate ? moment(x.endDate).format("DD/MM/YYYY") : "",
        expiryDate: x.expiryDate ? moment(x.expiryDate).format("DD/MM/YYYY") : "",
        supplier: x.supplier.name,
        region: x.region.name
      }))
    });
  }
  download() {
    json2csv(this.state.nodes.map(x => {
      Object.keys(x).forEach(y => {
        if(x[y] === null) x[y] = "";
      });
      return x;
    }), (err,csv) => {
      downloadFile(
        `${this.state.nodes[0].supplier.toLowerCase().replace(/ /g, "_")}_${this.props.tariff.name.toLowerCase().replace(/ /g, "_")}.csv`,
        "text/csv",
        csv
      );
    });
  }
  render() {
    return (
      this.state.confirmDeactivationModal ? <GenericConfirm title={`Are you sure?`} subTitle={this.props.tariff.name} body={`This will deactivate this tariff and it will be removed from the website and partner APIs.`} onExit={async yes => {
        if(yes) await this.props.api.post(`/tariffs/deactivate`, {
          supplierId: this.props.tariff.supplierId,
          name: this.props.tariff.name
        });
        this.setState({
          confirmDeactivationModal: false
        }, () => yes && this.props.onExit(true));
      }}/> : 
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card dark style={{ minWidth: "70vw" }}>
            <div className="padding-30 gap-16 col-1">
              <h1 className="ui-title-three margin-bottom-20">
                {
                  this.props.tariff.name
                }
              </h1>

              <Table compact data={this.state.nodes} style={{height: "50vh"}}/>

              <div className="col-2 gap-10" style={{ display: "grid", gridTemplateColumns: "auto min-content" }}>
              <div>
                <button
                  type="button"
                  className="ui-btn-compact margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Close
                </button>
                <button className="ui-btn-primary ui-btn-compact margin-top-20" onClick={() => this.download()}>
                  Download
                </button>
              </div>
              <div>
                <button className="ui-btn ui-btn-compact margin-top-20 bg-orange" onClick={() => this.setState({
                  confirmDeactivationModal: true
                })}>
                  Deactivate
                </button>
              </div>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default TariffOptions;
