import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Dashboard from "../views/Dashboard";
import Suppliers from "../views/Suppliers";
import Tariffs from "../views/Tariffs";
import Reporting from "../views/Reporting";
import Customers from "../views/Customers";
import Admin from "../views/Admin";

const Routes = props => (
  <Switch>
      <Route path="/portal/dashboard" render={internalProps => <Dashboard {...internalProps} {...props} />} />
      <Route path="/portal/suppliers" render={internalProps => <Suppliers {...internalProps} {...props} />} />
      <Route path="/portal/tariffs" render={internalProps => <Tariffs {...internalProps} {...props} />} />
      <Route path="/portal/reporting" render={internalProps => <Reporting {...internalProps} {...props} />} />
      <Route path="/portal/customers" render={internalProps => <Customers {...internalProps} {...props} />} />
      <Route path="/portal/admin" render={internalProps => <Admin {...internalProps} {...props} />} />
      <Route render={() => <Redirect to="/portal/dashboard" />} />
  </Switch>
);

export default Routes;
