import React from "react";
import moment from "moment";
import Table from "../components/generic/Table";

export default class extends React.Component {
    constructor() {
        super();
        this.state = {
            filter: "postcode",
            query: "",
            customers: []
        }
    }
    async search(){
        const res = await this.props.api.get(`/customers?filter=${this.state.filter}&query=${encodeURIComponent(this.state.query)}`);
        console.log(res.data.data)
        this.setState({
            customers: res.data.data.map(x => ({
                "Reference": x.id,
                "Full Name": `${x.title} ${x.firstName} ${x.lastName}`,
                "Address": `${x.addressLineOne ? x.addressLineOne + ", " : ""}${x.addressLineTwo ? x.addressLineTwo + ", " : ""}${x.addressLineThree ? x.addressLineThree + ", " : ""}${x.addressLineFour ? x.addressLineFour + ", " : ""}${x.postcode}`,
                "MPAN": x.mpan,
                "MPRN": x.mprn,
                "Email" : x.email,
                "Telephone": x.telephone,
                "Stage": x.stage,
                "Sale Date": moment(x.createdAt).format("DD/MM/YYYY HH:mm")
            }))
        });
    }
    render() {
        return (
            <div className="col-1 gap-10 padding-10" style={{ height: "calc(100vh - 60px)", gridTemplateRows: "min-content auto" }}>
                    <div className="col-3 gap-10" style={{ gridTemplateColumns: "300px 300px min-content" }}>
                        <select className="ui-select ui-select-compact" value={this.state.filter} onChange={e => this.setState({ filter: e.currentTarget.value })}>
                            <option value="postcode">Postcode</option>
                            <option value="id">Switch Reference</option>
                            <option value="mpan">MPAN</option>
                            <option value="mprn">MPRN</option>
                            <option value="firstName">First Name</option>
                            <option value="lastName">Last Name</option>
                            <option value="email">Email</option>
                            <option value="telephone">Telephone</option>
                        </select>
                        <input type="text" value={this.state.query} onChange={e => this.setState({ query: e.currentTarget.value })} className="ui-input"/>
                        <button onClick={() => this.search()} className="ui-btn-primary ui-btn-compact padding-left-16 padding-right-16"><i className="fa fa-search" /></button>

                </div>

                <Table onClick={i => {}} compact data={this.state.customers} />
            </div>
        )
    }
}