import React from "react";

export default props => <div className={`${props.className ? props.className : null} ui-status-page col-1 gap-20`}>
    <i className={`fad fa-${props.icon}`} />
    <div className="col-1 gap-5 text-center">
        <h1 className="ui-title-three">{props.heading}</h1>
        <h2 className="ui-title-four ui-title-sub">{props.subHeading}</h2>
        {
            props.buttonText ? <button className="ui-btn-primary margin-top-20" onClick={props.onButtonClick}>{props.buttonText}</button> : null
        }
    </div>
</div>