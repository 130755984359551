import React from "react";
import Modal from "../components/generic/Modal";
import Card from "../components/generic/Card";

class UpsertUser extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: props.user ? props.user.id : null,
      name: props.user ? props.user.name : "",
      email: props.user ? props.user.email : "",
      admin: props.user ? props.user.admin : false,
      analyst: props.user ? props.user.analyst : false,
      support: props.user ? props.user.support : false,
      sales: props.user ? props.user.sales : false,
      active: props.user ? props.user.active : false,
      password: ""
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  saveSupplier = async e => {
    e.preventDefault();
    const user = {
      name: this.state.name,
      email: this.state.email,
      admin: this.state.admin,
      analyst: this.state.analyst,
      support: this.state.support,
      sales: this.state.sales,
      active: this.state.active
    }
    if(this.state.id) user.id = this.state.id;
    if(this.state.password) user.password = this.state.password;
    await this.props.api.post("users", user);
    this.props.onExit(true);
  };
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card dark style={{ minWidth: 750, minHeight: 300 }}>
            <form className="padding-30 gap-16 col-1" onSubmit={this.saveSupplier}>
              <h1 className="ui-title-three margin-bottom-20">
                {
                  this.props.user ? `Update "${this.props.user.name}"` : "Create a User"
                }
                </h1>
              <div className="col-2 gap-16">
                <div className="col-1 gap-16">
                <div className="col-1 gap-10">
                  <p className="ui-label">Name</p>
                  <input
                    type="text"
                    required
                    className="ui-input"
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Email</p>
                  <input
                    type="email"
                    required
                    className="ui-input"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Password</p>
                  <input
                    type="password"
                    className="ui-input"
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Active</p>
                  <select className="ui-select" value={`${this.state.active ? "true" : "false"}`} onChange={e => this.setState({ active: e.currentTarget.value === "true" })}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                </div>
                <div className="col-1 gap-16">
                <div className="col-1 gap-10">
                  <p className="ui-label">Admin</p>
                  <select className="ui-select" value={`${this.state.admin ? "true" : "false"}`} onChange={e => this.setState({ admin: e.currentTarget.value === "true" })}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Analyst</p>
                  <select className="ui-select" value={`${this.state.analyst ? "true" : "false"}`} onChange={e => this.setState({ analyst: e.currentTarget.value === "true" })}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Support</p>
                  <select className="ui-select" value={`${this.state.support ? "true" : "false"}`} onChange={e => this.setState({ support: e.currentTarget.value === "true" })}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Sales</p>
                  <select className="ui-select" value={`${this.state.sales ? "true" : "false"}`} onChange={e => this.setState({ sales: e.currentTarget.value === "true" })}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="ui-btn-compact margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary ui-btn-compact margin-top-20">
                  {this.props.user ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default UpsertUser;
