import React from "react";
import Modal from "../components/generic/Modal";
import Card from "../components/generic/Card";
import GenericConfirm from "./GenericConfirm";

class UpsertPartner extends React.Component {
  constructor(props) {
    super();
    this.state = {
      id: props.partner ? props.partner.id : null,
      owner: props.partner ? props.partner.owner : "",
      key: props.partner ? props.partner.key : null,
      dataAccess: props.partner ? props.partner.dataAccess : false,
      verificationAccess: props.partner ? props.partner.verificationAccess : false,
      active: props.partner ? props.partner.active : false,
      resetModal: false,
      credentialModal: false,
      credentials: {}
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  savePartner = async e => {
    e.preventDefault();
    const res = await this.props.api.post("partners", this.state);
    if (this.state.id) {
      this.props.onExit(true);
    } else {
      this.setState({
        credentialModal: true,
        credentials: {
          secret: res.data.data.secret,
          key: res.data.data.key
        }
      });
    }
  };
  resetSecret = async () => {

  }
  render() {
    return (
      <Modal>
        {
          this.state.resetModal && <GenericConfirm title="Are you sure?" subTitle={`${this.props.partner.owner} will need to authenticate again`} body="This should only be used at the partners request or if credentials have been comprised."
            onExit={async yes => {
              if (yes) {
                const secret = await this.props.api.get(`partners/${this.state.id}/new-secret`);
                this.setState({
                  resetModal: false,
                  credentialModal: true,
                  credentials: {
                    secret: secret.data.data,
                    key: this.state.key
                  }
                });
              } else {
                this.setState({
                  resetModal: false
                });
              }
            }} />
        }

        {
          this.state.credentialModal && <GenericConfirm cancelText="Close" minWidth={750} disableConfirm={true} title="API Client ID &amp; Secret" subTitle={`${this.state.owner} will need these details`} body={<>The secret will only be shown once on this screen, please store securely.<br /><br />Key: <b className="colour-blue">{this.state.credentials.key}</b><br />Secret: <b className="colour-blue">{this.state.credentials.secret}</b></>}
            onExit={() => {
              this.setState({
                credentialModal: false,
                credentials: {}
              }, () => this.props.onExit(true));
            }} />
        }
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card dark style={{ minWidth: 400, minHeight: 300 }}>
            <form className="padding-30 gap-16 col-1" onSubmit={this.savePartner}>
              <h1 className="ui-title-three margin-bottom-20">
                {
                  this.props.supplier ? `Update "${this.props.partner.owner}"` : "Create a Partner"
                }
              </h1>
              <div className="col-1 gap-16">
                <div className="col-1 gap-10">
                  <p className="ui-label">Owner</p>
                  <input
                    type="text"
                    required
                    className="ui-input"
                    value={this.state.owner}
                    onChange={e => this.setState({ owner: e.currentTarget.value })}
                  />
                </div>
                {
                  this.props.partner && <div className="col-1 gap-10">
                    <p className="ui-label">API Client ID (<span className="colour-orange" onClick={() => this.setState({
                      resetModal: true
                    })}>Reset Secret</span>)</p>
                    <input
                      type="text"
                      required
                      className="ui-input"
                      value={this.state.key}
                      disabled
                      readOnly
                    />
                  </div>
                }
                <div className="col-1 gap-10">
                  <p className="ui-label">Data Access (<a href="https://docs.api.switchgasandelectric.co.uk/#e7150246-56e3-40da-86a5-d91b43dce0eb" className="ui-link" target="_blank">Docs</a>)</p>
                  <select className="ui-select" value={`${this.state.dataAccess ? "true" : "false"}`} onChange={e => this.setState({ dataAccess: e.currentTarget.value === "true" })}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Verification Access (<a href="https://docs.api.switchgasandelectric.co.uk/#15686e86-e318-4978-8350-79a3d5e6f82f" className="ui-link" target="_blank">Docs</a>)</p>
                  <select className="ui-select" value={`${this.state.verificationAccess ? "true" : "false"}`} onChange={e => this.setState({ verificationAccess: e.currentTarget.value === "true" })}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Active</p>
                  <select className="ui-select" value={`${this.state.active ? "true" : "false"}`} onChange={e => this.setState({ active: e.currentTarget.value === "true" })}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="ui-btn-compact margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary ui-btn-compact margin-top-20">
                  {this.props.partner ? "Update" : "Create"}
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default UpsertPartner;
