import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import SignIn from "../views/SignIn";
import Portal from "../views/Portal";

const Routes = () => (
  <Switch>
      <Route path="/sign-in" component={SignIn} />
      <Route path="/portal" component={Portal} />
      <Route render={() => <Redirect to="/sign-in" />} />
  </Switch>
);

export default Routes;
