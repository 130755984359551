import React from "react";
import Routes from "../routes/Portal";
import Axios from "../Axios";
import Header from "../components/layout/Header";
import { Redirect } from "react-router-dom";

class Portal extends React.Component {
  constructor() {
    super();
    this.state = {
      verified: null,
      loading: true,
      user: null
    };
  }
  componentDidMount() {
    this.verifyToken();
  }
  get api() {
    return Axios(localStorage.sges_key);
  }
  verifyToken = async () => {
    Axios(localStorage.sges_key)
      .get("auth/me")
      .then(res =>
        this.setState(
          {
            loading: false,
            verified: true,
            user: {
              ...res.data
            }
          }
        )
      )
      .catch(() => {
        localStorage.removeItem("sges_key");
        this.setState({
          loading: false,
          verified: false,
          user: null
        });
      });
  };
  render() {
    return this.state.loading ? (
      <div className="grid-center-center">

      </div>
    ) : this.state.verified ? (
      <div className="portal-container">
        <Header user={this.state.user}/>
        <Routes
          api={this.api}
          user={this.state.user}
          userReload={this.verifyToken}
        />
      </div>
    ) : (
      <Redirect to="/sign-in" />
    );
  }
}

export default Portal;
