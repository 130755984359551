import Axios from "axios";
import React from "react";
import StatusPage from "../../../components/generic/StatusPage";
import downloadFile from "../../../components/helpers/DownloadFile";
import { json2csv } from "json-2-csv";
import moment from "moment";

export default class extends React.Component {
    constructor() {
        super();
        this.references = [{ type: "Dual Fuel", band: "Low", region: "East England", reference: "53caef6e-648c-4f0b-939e-fe798eddc75c" },
        { type: "Dual Fuel", band: "Low", region: "East Midlands", reference: "e1ed79aa-f064-4fbd-8009-8043117c2e0b" },
        { type: "Dual Fuel", band: "Low", region: "London", reference: "0aeadd36-6522-4025-bc7b-a93260518c22" },
        { type: "Dual Fuel", band: "Low", region: "North Wales, Merseyside and Cheshire", reference: "31b7018a-411b-4853-b935-d27e2d4c9a76" },
        { type: "Dual Fuel", band: "Low", region: "West Midlands", reference: "5a8db66e-2491-43b9-a28c-74cfe8aa7d85" },
        { type: "Dual Fuel", band: "Low", region: "North East England", reference: "c1a900c1-7eb5-48b0-b7ed-ac7aeb3ff39e" },
        { type: "Dual Fuel", band: "Low", region: "North West England", reference: "2d2d8759-3610-4c53-b25b-e802fc6aed80" },
        { type: "Dual Fuel", band: "Low", region: "North Scotland", reference: "66b753bd-88d0-4f1d-9771-da6cd0298353" },
        { type: "Dual Fuel", band: "Low", region: "South and Central Scotland", reference: "53863d27-7051-4cbf-afe9-2ad3b36e8338" },
        { type: "Dual Fuel", band: "Low", region: "South East England", reference: "caf3c7b7-ac53-4709-8d54-5a392cd33d5a" },
        { type: "Dual Fuel", band: "Low", region: "Southern England", reference: "f61abc35-38e0-455e-b992-67701da02f71" },
        { type: "Dual Fuel", band: "Low", region: "South Wales", reference: "5fab37a0-3608-4b79-af33-203567ff4dbe" },
        { type: "Dual Fuel", band: "Low", region: "South West England", reference: "83ae86d9-6eb8-4c12-a203-36ceb5d02249" },
        { type: "Dual Fuel", band: "Low", region: "Yorkshire", reference: "016751dc-083c-4e06-802f-777db1b51768" },
        { type: "Dual Fuel", band: "Medium", region: "East England", reference: "00fbf014-1796-4c0c-a852-c2bf9afd22fc" },
        { type: "Dual Fuel", band: "Medium", region: "East Midlands", reference: "f3dc9800-d889-48ce-bb5e-304f850b3569" },
        { type: "Dual Fuel", band: "Medium", region: "London", reference: "7ab12f58-73df-4b02-af84-e602b5ad857a" },
        { type: "Dual Fuel", band: "Medium", region: "North Wales, Merseyside and Cheshire", reference: "4c238460-8ac0-469f-9230-e5ca0def2cc1" },
        { type: "Dual Fuel", band: "Medium", region: "West Midlands", reference: "4149d123-4bff-486c-ac2a-d7821e5cdd7d" },
        { type: "Dual Fuel", band: "Medium", region: "North East England", reference: "7c7289ae-9b14-456b-be5b-4d132d14ca89" },
        { type: "Dual Fuel", band: "Medium", region: "North West England", reference: "030fef8d-3c72-45f2-a2df-58c9d21f4794" },
        { type: "Dual Fuel", band: "Medium", region: "North Scotland", reference: "72fa0a4e-d627-42dd-a992-8842a0851718" },
        { type: "Dual Fuel", band: "Medium", region: "South and Central Scotland", reference: "ffa4a4f8-9539-4964-89f7-0ebdbab6596e" },
        { type: "Dual Fuel", band: "Medium", region: "South East England", reference: "1fd6078b-ce98-42d0-aed5-6f240db691f4" },
        { type: "Dual Fuel", band: "Medium", region: "Southern England", reference: "87cf546f-be7b-4292-b62f-3d995be25ce9" },
        { type: "Dual Fuel", band: "Medium", region: "South Wales", reference: "3ffb8e4f-bd5d-4e7d-9197-7e68db395d24" },
        { type: "Dual Fuel", band: "Medium", region: "South West England", reference: "eaa00c89-686f-4f1b-a842-c09e66cd9643" },
        { type: "Dual Fuel", band: "Medium", region: "Yorkshire", reference: "d6ba85fa-3448-43f4-a6cc-c8f20d2c84b0" },
        { type: "Dual Fuel", band: "High", region: "East England", reference: "74f28052-2649-4c9e-996b-a0aa4d9a3244" },
        { type: "Dual Fuel", band: "High", region: "East Midlands", reference: "6abdb03c-c664-473d-bfcd-75d625e1d12b" },
        { type: "Dual Fuel", band: "High", region: "London", reference: "b832896f-d5ec-44d3-9545-993b031ba73a" },
        { type: "Dual Fuel", band: "High", region: "North Wales, Merseyside and Cheshire", reference: "6ae7fd7b-51ab-4cfb-9f4b-74e0cfe1a51f" },
        { type: "Dual Fuel", band: "High", region: "West Midlands", reference: "eeb25055-76d9-4681-b8c6-6a78e64a2324" },
        { type: "Dual Fuel", band: "High", region: "North East England", reference: "94c6fc64-5879-413f-9eed-3bce873c1df1" },
        { type: "Dual Fuel", band: "High", region: "North West England", reference: "f3e62e6c-c0b2-4da8-ad67-3218978d4e2b" },
        { type: "Dual Fuel", band: "High", region: "North Scotland", reference: "8d12b331-809d-4ece-96e6-c04a720062c7" },
        { type: "Dual Fuel", band: "High", region: "South and Central Scotland", reference: "b7e213a6-93d8-4a47-aa72-f8d162022dd5" },
        { type: "Dual Fuel", band: "High", region: "South East England", reference: "945e380d-88f3-42d0-a4af-f06388ac99f8" },
        { type: "Dual Fuel", band: "High", region: "Southern England", reference: "fdffc15b-4da3-41d7-8ace-3ab559e7964e" },
        { type: "Dual Fuel", band: "High", region: "South Wales", reference: "df65425b-1661-416c-8800-de84642ecf37" },
        { type: "Dual Fuel", band: "High", region: "South West England", reference: "bbc9c4b9-7666-4504-997b-c026f918c7bc" },
        { type: "Dual Fuel", band: "High", region: "Yorkshire", reference: "415aac93-8331-40f6-ab28-e7f5a9e158da" },
        { type: "Electric", band: "Low", region: "East England", reference: "246a0815-f98e-4bef-b4da-aa479749f418" },
        { type: "Electric", band: "Low", region: "East Midlands", reference: "fe5c1333-7c92-4fa4-95e2-55ef45155d14" },
        { type: "Electric", band: "Low", region: "London", reference: "e35f28b0-494e-42a3-b5bc-f09352642537" },
        { type: "Electric", band: "Low", region: "North Wales, Merseyside and Cheshire", reference: "ec7c10ba-c39a-4200-80b7-0814e21d478b" },
        { type: "Electric", band: "Low", region: "West Midlands", reference: "b09a3c11-1ccb-483a-961e-23b0025be0db" },
        { type: "Electric", band: "Low", region: "North East England", reference: "833a754a-3698-4337-add5-f39794c6fbc6" },
        { type: "Electric", band: "Low", region: "North West England", reference: "bfc1f137-2179-4ee4-9474-ee2d29e850c5" },
        { type: "Electric", band: "Low", region: "North Scotland", reference: "1fd84fb4-2ea6-4f4e-a5c2-5e5ff596db4d" },
        { type: "Electric", band: "Low", region: "South and Central Scotland", reference: "ee4237b4-7866-4387-96f5-391d7b492353" },
        { type: "Electric", band: "Low", region: "South East England", reference: "bd486f35-1332-4dcb-9421-8cb50c735906" },
        { type: "Electric", band: "Low", region: "Southern England", reference: "b6dc0488-8f17-4939-912f-527927c2588d" },
        { type: "Electric", band: "Low", region: "South Wales", reference: "aaa71eca-a9a3-45f1-b451-8f8270dcbf63" },
        { type: "Electric", band: "Low", region: "South West England", reference: "58ff2829-bd18-41f0-b21b-71691c4b8239" },
        { type: "Electric", band: "Low", region: "Yorkshire", reference: "f6d4f320-ae1a-4c0f-a738-a8f5d7b08cf7" },
        { type: "Electric", band: "Medium", region: "East England", reference: "de567254-02f5-4770-8d09-9baf0c4ec5d9" },
        { type: "Electric", band: "Medium", region: "East Midlands", reference: "1cae81b1-6544-42ef-90dc-53d3b8b45319" },
        { type: "Electric", band: "Medium", region: "London", reference: "bc8337dd-9310-4436-8ef0-083af1f40ab1" },
        { type: "Electric", band: "Medium", region: "North Wales, Merseyside and Cheshire", reference: "e1d398e6-96d6-4313-ad19-ddce9ca83fd7" },
        { type: "Electric", band: "Medium", region: "West Midlands", reference: "7d8b4674-a574-4f91-87a8-83e6018bb12f" },
        { type: "Electric", band: "Medium", region: "North East England", reference: "d7f2f13a-d24d-40ea-a4a4-a8a69c899bde" },
        { type: "Electric", band: "Medium", region: "North West England", reference: "6a46a07f-bfb5-4acb-96c7-a8ec9ec00480" },
        { type: "Electric", band: "Medium", region: "North Scotland", reference: "9a29f025-efba-40da-9586-dd0782140b66" },
        { type: "Electric", band: "Medium", region: "South and Central Scotland", reference: "c4b63998-f971-44db-b08a-d0e3dd8b365e" },
        { type: "Electric", band: "Medium", region: "South East England", reference: "dd4cb166-83ed-411c-8d56-8672e79be0bb" },
        { type: "Electric", band: "Medium", region: "Southern England", reference: "69db5f9b-d9df-442a-95a2-c3037f8cf988" },
        { type: "Electric", band: "Medium", region: "South Wales", reference: "5e22f20a-ccd3-484a-adff-25468833a950" },
        { type: "Electric", band: "Medium", region: "South West England", reference: "076d654c-15e5-4904-82bf-fe5b7fb55a15" },
        { type: "Electric", band: "Medium", region: "Yorkshire", reference: "36fbe29b-c3b8-490d-9546-43f20afe8eda" },
        { type: "Electric", band: "High", region: "East England", reference: "4bde2d85-0577-4777-a8fd-984d6aef3a3b" },
        { type: "Electric", band: "High", region: "East Midlands", reference: "7a63bb18-975d-4bcc-9136-ff6e38b557b4" },
        { type: "Electric", band: "High", region: "London", reference: "f9c0cabb-cb35-4e14-9e97-ed2b37b16206" },
        { type: "Electric", band: "High", region: "North Wales, Merseyside and Cheshire", reference: "b9016bc7-4d3e-4d24-95bd-dfc72cf49d5c" },
        { type: "Electric", band: "High", region: "West Midlands", reference: "17bf4aae-3130-4e46-893a-51f6e9d6f229" },
        { type: "Electric", band: "High", region: "North East England", reference: "bf4de3f9-1191-4ce1-9e3e-c04e1808af4c" },
        { type: "Electric", band: "High", region: "North West England", reference: "7d4bf38e-109d-45ec-9295-1c77d70bb38e" },
        { type: "Electric", band: "High", region: "North Scotland", reference: "fd708adf-f75f-4bc2-a39b-2285c3c00f27" },
        { type: "Electric", band: "High", region: "South and Central Scotland", reference: "c791d71c-6ac6-4ed4-80ca-ed5ed98e6421" },
        { type: "Electric", band: "High", region: "South East England", reference: "93b46e7e-f06f-48f5-9a56-54d258c7710d" },
        { type: "Electric", band: "High", region: "Southern England", reference: "a20c1f2b-6e65-40da-9f97-e924112e65ed" },
        { type: "Electric", band: "High", region: "South Wales", reference: "6b3b07bf-b0f6-453b-8e04-29d0672dbcf9" },
        { type: "Electric", band: "High", region: "South West England", reference: "9b33e1c2-c53e-4319-852a-3fea285ebb1f" },
        { type: "Electric", band: "High", region: "Yorkshire", reference: "e793d42a-f20e-49d9-89d6-cd06071f2fc9" },
        { type: "Gas", band: "Low", region: "East England", reference: "2889b6c8-eff2-4ec5-9bed-d7f88684c58c" },
        { type: "Gas", band: "Low", region: "East Midlands", reference: "773a9ec0-6176-4e20-a201-aa99191384b4" },
        { type: "Gas", band: "Low", region: "London", reference: "b7533f3b-9fe2-4c7e-a1e2-39d7ac60849f" },
        { type: "Gas", band: "Low", region: "North Wales, Merseyside and Cheshire", reference: "5560fba8-4827-496c-a3f8-e90ec24c19cd" },
        { type: "Gas", band: "Low", region: "West Midlands", reference: "69f4f8f2-dae2-4b03-80a0-55b7985fdf4a" },
        { type: "Gas", band: "Low", region: "North East England", reference: "5f6c403d-9c59-456b-a7d2-49b56b832ffd" },
        { type: "Gas", band: "Low", region: "North West England", reference: "14001a35-b4b5-423f-a2b3-54c850441f64" },
        { type: "Gas", band: "Low", region: "North Scotland", reference: "7433f6a6-1152-41f6-ada1-c61d17eaaf35" },
        { type: "Gas", band: "Low", region: "South and Central Scotland", reference: "b287ca07-d852-4e16-8cd6-9d1edf9f237a" },
        { type: "Gas", band: "Low", region: "South East England", reference: "3a165f5c-8897-4b2c-841e-bb7bdfd1d28a" },
        { type: "Gas", band: "Low", region: "Southern England", reference: "c2e93699-8a57-49f1-b6b9-9eef8397aa14" },
        { type: "Gas", band: "Low", region: "South Wales", reference: "cdb7b053-bda9-4d8d-b53f-9d41cc7b3bd7" },
        { type: "Gas", band: "Low", region: "South West England", reference: "a494da0d-20cb-4b6d-b1a2-3faf1f6805a0" },
        { type: "Gas", band: "Low", region: "Yorkshire", reference: "ca68cf45-5f2e-40fa-b0a4-6def69bcc974" },
        { type: "Gas", band: "Medium", region: "East England", reference: "4a629cee-8ab0-4f74-bbcb-d8bc3365e4ac" },
        { type: "Gas", band: "Medium", region: "East Midlands", reference: "3f095e0c-2885-4f6a-ae23-403e76391ba3" },
        { type: "Gas", band: "Medium", region: "London", reference: "3fe51634-0032-42bc-af01-24b4f84ab73c" },
        { type: "Gas", band: "Medium", region: "North Wales, Merseyside and Cheshire", reference: "fe8de169-aa31-44c4-9e8c-3a8b3e485646" },
        { type: "Gas", band: "Medium", region: "West Midlands", reference: "16b4e410-d519-4c18-b186-9eac67f69c25" },
        { type: "Gas", band: "Medium", region: "North East England", reference: "a2ced250-a677-4f8e-a7c9-4fa26b436eb5" },
        { type: "Gas", band: "Medium", region: "North West England", reference: "b0da6159-a50d-40b6-ac75-5431c9d5be99" },
        { type: "Gas", band: "Medium", region: "North Scotland", reference: "bc05e574-03b2-420e-a1c3-0e444a16cc80" },
        { type: "Gas", band: "Medium", region: "South and Central Scotland", reference: "4414fc74-720f-496b-8aa3-6af018f00009" },
        { type: "Gas", band: "Medium", region: "South East England", reference: "2eaac4b8-1341-47f6-b2fe-728a0bfd54ea" },
        { type: "Gas", band: "Medium", region: "Southern England", reference: "478eab56-5c47-4f41-8a2a-5020c48e138a" },
        { type: "Gas", band: "Medium", region: "South Wales", reference: "89161255-5c40-499d-9a9f-e5e73d7d7400" },
        { type: "Gas", band: "Medium", region: "South West England", reference: "73eb8a1f-d643-4d4c-9eb0-3172c52cc59d" },
        { type: "Gas", band: "Medium", region: "Yorkshire", reference: "41ce125a-92a7-4390-bac0-f43c62cd24fb" },
        { type: "Gas", band: "High", region: "East England", reference: "2f74ba1f-fc53-4fc5-abd5-d741ba6be18a" },
        { type: "Gas", band: "High", region: "East Midlands", reference: "26ba8aa1-140e-4c0f-8c33-720b96cd48c5" },
        { type: "Gas", band: "High", region: "London", reference: "a4942f7c-e86b-4d50-9449-f36df9e31770" },
        { type: "Gas", band: "High", region: "North Wales, Merseyside and Cheshire", reference: "70d5d2e0-7d55-4210-9030-c5a8f0ea615b" },
        { type: "Gas", band: "High", region: "West Midlands", reference: "c6d142b0-fc59-4ee2-b763-4e002a2ad4f8" },
        { type: "Gas", band: "High", region: "North East England", reference: "0bd4c0c3-b492-46da-b4fb-3fcd7aabfdb0" },
        { type: "Gas", band: "High", region: "North West England", reference: "674f26a8-97d4-4381-8a29-dcc0b687d317" },
        { type: "Gas", band: "High", region: "North Scotland", reference: "4931747d-27d2-40c2-b190-e6113ac010e8" },
        { type: "Gas", band: "High", region: "South and Central Scotland", reference: "a107b82e-90fc-4ce4-b8a0-3611aa7a19f4" },
        { type: "Gas", band: "High", region: "South East England", reference: "5fc8fc16-c6ce-49f1-97f3-b79bafc876da" },
        { type: "Gas", band: "High", region: "Southern England", reference: "2b0d0adc-8d34-4dd5-930c-49e4d9cbeaa9" },
        { type: "Gas", band: "High", region: "South Wales", reference: "f279f76e-6846-41cd-a404-65a48aac89a3" },
        { type: "Gas", band: "High", region: "South West England", reference: "251edde5-a166-4490-89ef-7abdb39d931f" },
        { type: "Gas", band: "High", region: "Yorkshire", reference: "c3e37ead-834e-4a43-9b60-0621081cfa34" }];
        this.state = {
            data: null,
            callsComplete: 1,
            loading: false,
            processingText: ""
        }
    }
    download() {
        json2csv(this.state.data, (err,csv) => {
          downloadFile(
            `live_view_audit_${moment().format("DDMMYYYYHHmm")}.csv`,
            "text/csv",
            csv
          );
        });
      }
    async startReport(){
        this.setState({
            loading: true,
            data: [],
            processingText: `${this.references[0].type} - ${this.references[0].band} Usage - ${this.references[0].region}`
        }, async () => {
            for(let i = 0;i < this.references.length;i++){
                const res = await Axios.get(`https://api.switchgasandelectric.co.uk/quote/${this.references[i].reference}?key=16f00c79-248b-4399-8790-987ebb02c31c`);
                this.setState({
                    callsComplete: i+1,
                    processingText: (this.references.length-1) !== i ? `${this.references[i+1].type} - ${this.references[i+1].band} Usage - ${this.references[i+1].region}` : "Generating report...",
                    data: this.state.data.concat(res.data.data.tariffs.map(x => ({
                        "Type": this.references[i].type,
                        "Usage Band": this.references[i].band,
                        "Region": this.references[i].region,
                        "Supplier": x.supplier.name,
                        "Tariff": x.tariff.name,
                        "Tariff ID": `${x.tariff.id}`,
                        "Switchable": x.tariff.switchable,
                        "Tariff Type": x.tariff.fixed ? "Fixed" : "Variable",
                        "Term": x.tariff.fixed ? x.tariff.term : "",
                        "Gas Cost": x.cost.gas ? x.cost.gas.toLocaleString("en-GB", { style: "currency", currency: "GBP" }) : "",
                        "Electric Cost": x.cost.electric ? x.cost.electric.toLocaleString("en-GB", { style: "currency", currency: "GBP" }) : "",
                        "Total Cost": x.cost.total ? x.cost.total.toLocaleString("en-GB", { style: "currency", currency: "GBP" }) : "",
                        "Monthly Cost": x.cost.monthly ? x.cost.monthly.toLocaleString("en-GB", { style: "currency", currency: "GBP" }) : "",
                        "Annual Saving": x.cost.saving ? x.cost.saving.toLocaleString("en-GB", { style: "currency", currency: "GBP" }) : ""
                    })))
                });
            }
            this.setState({
                loading: false
            });
        });
    }
    render() {
        return this.state.data === null || this.state.loading ? <StatusPage heading={this.state.loading ? `Running Config ${this.state.callsComplete} of ${this.references.length}` : "Live View Report"} subHeading={this.state.loading ? this.state.processingText : "Automatically check all configs on our site."} icon={this.state.loading ? "spinner-third fa-spin" : "file-spreadsheet"} buttonText={this.state.loading ? "" : "Start Report"} onButtonClick={() => this.startReport()} /> : <StatusPage heading={"Your Report is Ready"} subHeading={"Please click download below."} icon={"check-circle"} buttonText={"Download CSV"} onButtonClick={() => this.download()} />
    }
}
