import React from "react";
import { Redirect, NavLink } from "react-router-dom";

export default props => <div className="header">
    <img src={require("../../assets/logo.svg")} />
    <div className="navigation">
        <NavLink to="/portal/dashboard" activeClassName="active" className="ui-label">Dashboard</NavLink>
        {props.user.analyst ? <NavLink to="/portal/suppliers" activeClassName="active" className="ui-label">Suppliers</NavLink> : null}
        {props.user.analyst ? <NavLink to="/portal/tariffs" activeClassName="active" className="ui-label">Tariffs</NavLink> : null}
        {props.user.admin ? <NavLink to="/portal/customers" activeClassName="active" className="ui-label">Customers</NavLink> : null}
        {props.user.admin ? <NavLink to="/portal/reporting" activeClassName="active" className="ui-label">Reporting</NavLink> : null}
        {props.user.admin ? <NavLink to="/portal/admin" activeClassName="active" className="ui-label">Admin</NavLink> : null}
    </div>
    <div className="user-options">
        <NavLink to="/sign-in" activeClassName="active" className="ui-label" onClick={() => localStorage.removeItem("sges_key")}>
            <p>
                {props.user.name}
                <i className="fad fa-sign-out"></i>
            </p>
        </NavLink>
    </div>
</div>